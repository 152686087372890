import React from "react";
import { Link } from "gatsby";

import SocialMediaLogos from "./SocialMedia";
import Logo from "../../images/horizontal_logo.png";

import "./styles/HorizontalNavBar.scss";

const HorizontalNavBar = () => {
  return (
    <div className="bg-color header-box-shadow sticky-top">
      <header className="wrapper">
        <div className="w-full flex items-center justify-between">
          <h1>
            <Link to="/">
              <img
                src={Logo}
                className="logo"
                placeholder="blurred"
                alt="Dana For Cambridge City Council Logo"
              />
            </Link>
          </h1>
          <SocialMediaLogos />
          <nav>
            <Link to="/#why-content" className="mr-11 link-font ">
              ABOUT DANA
            </Link>
            <Link to="/#policies-page" className="mr-11 link-font ">
              ISSUES
            </Link>
            <Link to="/#news" className="mr-11 link-font ">
              NEWS
            </Link>
            <Link
              to="/#joinmailinglist"
              className="mr-11 link-font"
            //onClick, run function that embeds volunteerPage
            >
              JOIN MAILING LIST
            </Link>
            <Link to="#volunteer" className="mr-11 link-font">
              VOLUNTEER
            </Link>
            <Link
              to="https://secure.actblue.com/donate/dana-bullister-1?commit=Contribute"
              className="mr-11 link-font "
              id="navDonate"
            >
              DONATE
            </Link>
          </nav>
        </div>
      </header>
    </div>
  );
};

export default HorizontalNavBar;
