import * as React from "react";
import { NavBar } from "../components/NavBar";

const ContactPage = () => {
	return (
		<main>
			<NavBar />
			<section>
				<h2>Contact Us</h2>I am Dana's contact page
			</section>
		</main>
	);
};

export default ContactPage;
