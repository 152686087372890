import * as React from "react";
import { useState, useLayoutEffect, useEffect } from "react";
import window from "global";

import "./styles/NavBar.scss";
import VerticalNavBar from "./VerticalNavBar";
import HorizontalNavBar from "./HorizontalNavBar";

const BREAKPOINT = 1300;

const NavBar = () => {
  const [horizontalMenu, setHorizontalMenu] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);

  let isSmallWidth = width < BREAKPOINT;

  useEffect(() => {
    setHorizontalMenu(!isSmallWidth);
    return { isSmallWidth };
  }, [width, isSmallWidth]);

  useLayoutEffect(() => {
    window.addEventListener("resize", onWindowResize);
    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);

  const onWindowResize = () => {
    // can use ref and also throttle this func
    setWidth(window.innerWidth);
  };

  return horizontalMenu ? <HorizontalNavBar /> : <VerticalNavBar />;
};
export default NavBar;
