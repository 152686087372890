import React from "react";
import FacebookLogo from "../../images/assets/facebook.svg";
import TwitterLogo from "../../images/assets/twitter.svg";
import InstagramLogo from "../../images/assets/instagram.svg";

const SMLogos = [
  {
    Logo: FacebookLogo,
    link: "https://www.facebook.com/danaforcambridge",
  },
  { Logo: TwitterLogo, link: "https://twitter.com/DanaBullister" },
  {
    Logo: InstagramLogo,
    link: "https://www.instagram.com/danaforcambridge/",
  },
];

const SocialMediaLogos = () => {
  return (
    <div className="socialMediaLogos">
      {SMLogos.map(({ Logo, link }, index) => (
        <a key={index} href={link}>
          <Logo />
        </a>
      ))}
    </div>
  );
};

export default SocialMediaLogos;
