import * as React from "react";
import { useState } from "react";
import { Link } from "gatsby";

import Logo from "../../images/horizontal_logo.png";

import "./styles/VerticalNavBar.scss";

const VerticalNavBar = () => {
  const [showNav, setShowNav] = useState(false);
  const navClassName = showNav
    ? { nav: "showNavBar", wrapper: "openedHeaderHeight" }
    : { nav: "hideNavBar", wrapper: "headerHeight" };
  return (
    <div
      className={`bg-color header-box-shadow sticky-top ${navClassName.wrapper}`}
    >
      <header className="wrapper">
        <div className="navBarWrapper">
          <h1>
            <Link to="/">
              <img
                src={Logo}
                className="logo"
                placeholder="blurred"
                alt="Dana For Cambridge City Council Logo"
              />
            </Link>
          </h1>
          {/* <SocialMediaLogos /> */}
          <nav onClick={() => setShowNav(!showNav)} className="navBarMenu">
            <div className="menuContainer">
              <div className="menu"></div>
              <div className="menu"></div>
              <div className="menu"></div>
            </div>
          </nav>
        </div>
        {showNav ? (
          <nav className={navClassName.nav}>
            <Link to="#why-content" className="link-font ">
              <span>ABOUT DANA</span>
            </Link>
            <Link to="#policies-page" className="mr-9 link-font ">
              <span>ISSUES</span>
            </Link>
            <Link to="/#news" className="mr-9 link-font ">
              <span>NEWS</span>
            </Link>
            <Link to="#joinmailinglist" className="mr-9 link-font">
              <span>JOIN MAILING LIST</span>
            </Link>
            <Link to="#volunteer" className="mr-9 link-font">
              <span>VOLUNTEER</span>
            </Link>
            <Link
              to="https://secure.actblue.com/donate/dana-bullister-1?commit=Contribute"
              className="mr-9 link-font "
            >
              <span>DONATE</span>
            </Link>
          </nav>
        ) : null}
      </header>
    </div>
  );
};

export default VerticalNavBar;
